.App {
  text-align: center;
}

#text1{
  width: 70%; /*親要素いっぱい広げる*/
  padding: 10px 15px; /*ボックスを大きくする*/
  font-size: 16px;
  border-radius: 3px; /*ボックス角の丸み*/
  border: 2px solid #ddd; /*枠線*/
  box-sizing: border-box; /*横幅の解釈をpadding, borderまでとする*/
  margin-bottom: 2rem;
}

#text1:focus {
  border: 2px solid #ff9900; 
  z-index: 10;
  outline: 0;
}

button.btn1 {
	display: flex;
	
	text-decoration: none;
	width: 6rem;
	margin: auto;
  padding-left: 2rem;
	font-weight: bold;
	border: 2px solid #27acd9;
	color: #27acd9;
	transition: 0.5s;
  border-radius: 10%;
}
button.btn2 {
	display: flex;
  padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	text-decoration: none;
	width: 7rem;
	margin: auto;
  padding-left: 2rem;
	font-weight: bold;
	border: 2px solid #27acd9;
	color: #27acd9;
	transition: 0.5s;
  border-radius: 10%;
}
button:hover {
	color: #fff;
	background: #27acd9;
}

button.btn3 {
	margin-top: 0.7rem;
	display: flex;
  padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	text-decoration: none;
	width: auto;
	margin: auto;
	font-weight: bold;
	border: 2px solid #27acd9;
	color: #27acd9;
	transition: 0.5s;
  border-radius: 10%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1rem;
}

a{
	text-decoration: none;
	color: #27acd9;
}